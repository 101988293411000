import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import DangerBoxURL from 'components/1440p_Series_CGI_List/DangerBoxURL';
import PrimaryBox from 'components/1440p_Series_CGI_List/PrimaryBoxH265Warning';
import { EuiSpacer } from '@elastic/eui';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='1440p Serie CGI List' dateChanged='2019-09-20' author='Mike Polinowski' tag='INSTAR IP Camera' description='1440p Serie CGI List' image='/images/Search/AU_SearchThumb_CGICommands_1440p.png' twitter='/images/Search/AU_SearchThumb_CGICommands_1440p.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/1440p_Series_CGI_List/' locationFR='/fr/1440p_Series_CGI_List/' crumbLabel="1440p CGIs" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "1440p-series-cgi-list",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1440p-series-cgi-list",
        "aria-label": "1440p series cgi list permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1440p Series CGI List`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#p-series-cgi-list"
        }}>{`p Series CGI List`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#web-user-interface-menus"
            }}>{`Web User Interface Menus`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#response-codes"
            }}>{`Response Codes`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#smarthome-integration-eg-node-red"
            }}>{`Smarthome Integration e.g. Node-RED`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#camera-control"
                }}>{`Camera Control`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#livestream"
                }}>{`Livestream`}</a>
                <ul parentName="li">
                  <li parentName="ul"><a parentName="li" {...{
                      "href": "#jpeg-stream-snapcgi"
                    }}>{`JPEG Stream /snap.cgi`}</a></li>
                  <li parentName="ul"><a parentName="li" {...{
                      "href": "#http-stream-livestreamactionplaymediatype"
                    }}>{`HTTP Stream /livestream?action=play&media={type}`}</a>
                    <ul parentName="li">
                      <li parentName="ul"><a parentName="li" {...{
                          "href": "#fragmented-mp4-stream-livestreamactionplaymediafmp4"
                        }}>{`Fragmented MP4 Stream /livestream?action=play&media=fmp4`}</a></li>
                      <li parentName="ul"><a parentName="li" {...{
                          "href": "#mjpeg-stream-livestreamactionplaymediamjpeg"
                        }}>{`MJPEG Stream /livestream?action=play&media=mjpeg`}</a></li>
                    </ul>
                  </li>
                  <li parentName="ul"><a parentName="li" {...{
                      "href": "#rtsp-stream-livestream"
                    }}>{`RTSP Stream /livestream`}</a></li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "web-user-interface-menus",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#web-user-interface-menus",
        "aria-label": "web user interface menus permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Web User Interface Menus`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/1440p_Series_CGI_List/Alarm_Menu/"
        }}>{`Alarm Menu`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/1440p_Series_CGI_List/Features_Menu/"
        }}>{`Features Menu`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/1440p_Series_CGI_List/Multimedia_Menu/"
        }}>{`Multimedia Menu`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/1440p_Series_CGI_List/Recording_Menu/"
        }}>{`Recording Tasks Menu`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/1440p_Series_CGI_List/Network_Menu/"
        }}>{`Network Menu`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/1440p_Series_CGI_List/System_Menu/"
        }}>{`System Menu`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/1440p_Series_CGI_List/Smarthome_Menu/"
        }}>{`Smarthome Menu`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/1440p_Series_CGI_List/Complete_CGI_List/"
        }}>{`Complete CGI List`}</a></li>
    </ul>
    <p>{`After sending a command to the camera you always get two pieces of information back - a return code and the name of the command to which the update was sent, e.g.:`}</p>
    <blockquote>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "http://192.168.2.64/param.cgi?cmd=setaudioalarmattr&enable=0"
        }}>{`http://192.168.2.64/param.cgi?cmd=setaudioalarmattr&enable=0`}</a></p>
    </blockquote>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}>{`cmd=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"setaudioalarmattr"`}</span>{`;
response=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span>{`;`}</code></pre></div>
    <p>{`A return of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`200`}</code>{` means everything worked. Below is a breakdown of all the possible codes.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <DangerBoxURL mdxType="DangerBoxURL" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "response-codes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#response-codes",
        "aria-label": "response codes permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Response Codes`}</h2>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}>{`response=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"200"`}</span>{`;`}</code></pre></div>
    <ul>
      <li parentName="ul"><strong parentName="li">{`SUCCESS`}</strong>{` = `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`200`}</code></li>
      <li parentName="ul"><strong parentName="li">{`UNKNOWN_PARAM`}</strong>{` = `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`201`}</code></li>
      <li parentName="ul"><strong parentName="li">{`INVALID_VALUE`}</strong>{` = `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`202`}</code></li>
      <li parentName="ul"><strong parentName="li">{`NOT_IMPLEMENTED`}</strong>{` = `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`203`}</code></li>
      <li parentName="ul"><strong parentName="li">{`UNKNOWN_COMMAND`}</strong>{` = `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`204`}</code></li>
      <li parentName="ul"><strong parentName="li">{`READ_CONFIG_ERROR`}</strong>{` = `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`205`}</code></li>
      <li parentName="ul"><strong parentName="li">{`WRITE_CONFIG_ERROR`}</strong>{` = `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`206`}</code></li>
      <li parentName="ul"><strong parentName="li">{`UNKNOWN`}</strong>{` = `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`207`}</code></li>
      <li parentName="ul"><strong parentName="li">{`MISSING_PARAM`}</strong>{` = `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`208`}</code></li>
      <li parentName="ul"><strong parentName="li">{`OS_ERROR`}</strong>{` = `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`209`}</code></li>
      <li parentName="ul"><strong parentName="li">{`LOGIC_ERROR`}</strong>{` = `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`210`}</code></li>
      <li parentName="ul"><strong parentName="li">{`NO_PERMISSION`}</strong>{` = `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`211`}</code></li>
      <li parentName="ul"><strong parentName="li">{`INVALID_ACTION`}</strong>{` = `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`212`}</code></li>
      <li parentName="ul"><strong parentName="li">{`BUSY`}</strong>{` = `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`213`}</code></li>
      <li parentName="ul"><strong parentName="li">{`INTERNAL_ERROR`}</strong>{` = `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`214`}</code></li>
      <li parentName="ul"><strong parentName="li">{`DISABLED`}</strong>{` = `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`215`}</code></li>
      <li parentName="ul"><strong parentName="li">{`NOT_AVAIL`}</strong>{` = `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`216`}</code></li>
      <li parentName="ul"><strong parentName="li">{`NOT_HARDWARE_SUPPORTED`}</strong>{` = `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`217`}</code></li>
      <li parentName="ul"><strong parentName="li">{`IP_BLOCKED`}</strong>{` = `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`218`}</code></li>
      <li parentName="ul"><strong parentName="li">{`UNCHANGED`}</strong>{` = `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`219`}</code></li>
      <li parentName="ul"><strong parentName="li">{`SYNTAX_ERROR`}</strong>{` = `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`301`}</code></li>
      <li parentName="ul"><strong parentName="li">{`UPLOAD_FAILED`}</strong>{` = `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`302`}</code></li>
      <li parentName="ul"><strong parentName="li">{`UD_SOCKET_ERROR`}</strong>{` = `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`303`}</code></li>
    </ul>
    <h2 {...{
      "id": "smarthome-integration-eg-node-red",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#smarthome-integration-eg-node-red",
        "aria-label": "smarthome integration eg node red permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Smarthome Integration (e.g. Node-RED)`}</h2>
    <h3 {...{
      "id": "camera-control",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#camera-control",
        "aria-label": "camera control permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Camera Control`}</h3>
    <p><em parentName="p">{`Example Flows for Node-RED v3:`}</em></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e35df07a16fcb45af91c7c4a8b38407f/46e30/GET_CGI_Commands_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46.52173913043478%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACA0lEQVQoz03OXW/SYBQA4F6od8YPtmwJjo8tZsg+dGYLw2WLmV76N8wuTLw13Eg3aAuFDHSwbPMn6GKMiVd6YdQIMvq+JcAKM4NtyVgLpTAKdn1LMTgxnjw5OScnJ+dgcxu5+c38zNquI9I1HeEcUc653tOrZ6OcM7q7sJl7sJV7uJVbfLX3aIPBVKQihDq90BDSNdTpaP9Buq52OpqC9M8F+Uuh8eNQ/n7Y/ModY4Io1eXWmdZWUPuXqvE8f1wqCeVK6YQ/4XlRrIrVqlARW416Tmga3MmLroQBT2LPdsaIb5jcONV1/e9dXdfb2j9tDZ2P2n9ysapMrKZsNDsdTg37Uosv4hhbLHNC82dFyZeVfVE5qiO+gQS5qyyjgqQeSCp/elZroeRRw0zCgRVmxAevuIEjEMOevjt4/Kbw5G1xabuwtL1/L5q5HUrPRbOTofRsJO1cy9wNZ+bXs3fCacfLtIUEAyuMlQLXcOAMxrD3WekDJ33aq33M116zFXsA3vAy40HW6GVG/WDUD6wUmAiyJgLYA9BGwxEKTIVSRgLeD8exPjx5yZW4jjMXXImr7h0rBfqXGasPGnDGRAITCQY9YNgH+5cZCwmNHtCHMxYKXn4OZugYNh5kzSQYC7JmAtyioZ2G562JADY/tPnhkBfY6O6aPQBv+qC5+wI76IELq/HfggFuaWTKlf0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e35df07a16fcb45af91c7c4a8b38407f/e4706/GET_CGI_Commands_01.avif 230w", "/en/static/e35df07a16fcb45af91c7c4a8b38407f/d1af7/GET_CGI_Commands_01.avif 460w", "/en/static/e35df07a16fcb45af91c7c4a8b38407f/7f308/GET_CGI_Commands_01.avif 920w", "/en/static/e35df07a16fcb45af91c7c4a8b38407f/06e26/GET_CGI_Commands_01.avif 1303w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e35df07a16fcb45af91c7c4a8b38407f/a0b58/GET_CGI_Commands_01.webp 230w", "/en/static/e35df07a16fcb45af91c7c4a8b38407f/bc10c/GET_CGI_Commands_01.webp 460w", "/en/static/e35df07a16fcb45af91c7c4a8b38407f/966d8/GET_CGI_Commands_01.webp 920w", "/en/static/e35df07a16fcb45af91c7c4a8b38407f/78953/GET_CGI_Commands_01.webp 1303w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e35df07a16fcb45af91c7c4a8b38407f/81c8e/GET_CGI_Commands_01.png 230w", "/en/static/e35df07a16fcb45af91c7c4a8b38407f/08a84/GET_CGI_Commands_01.png 460w", "/en/static/e35df07a16fcb45af91c7c4a8b38407f/c0255/GET_CGI_Commands_01.png 920w", "/en/static/e35df07a16fcb45af91c7c4a8b38407f/46e30/GET_CGI_Commands_01.png 1303w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e35df07a16fcb45af91c7c4a8b38407f/c0255/GET_CGI_Commands_01.png",
              "alt": "Example GET Flow for Node-RED v3",
              "title": "Example GET Flow for Node-RED v3",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><a parentName="p" {...{
        "href": "https://wiki.instar.com/de/Node-RED_Flows/wqhd_get_cgis.json"
      }}>{`GET Commands`}</a></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/83e20dbb9f79eab80ae95306927ef631/5f652/SET_CGI_Commands_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7rAAAO6wFxzYGVAAABbElEQVQY0z1Ry2rbUBDVP8Wh3hTyFYXusukH9FOax6LdFrIIXfUHUlqyLzhErmWrWMGya8mSfN+PeZSrgA/DMA84cziTXXxZvP28OL/J34wxuZ6n+jafjjG5zs+u8slVPr15bednafI8vZ2ff3rKQvAhRj6B8KBCqyGVzER0WjBBjEgjmHlod9m26TppdEDpMWWlfhbdXgZAdAG0dcSMzNaHfhBSaaUNEAFSsSozJcWJnoitUT+KzkVkwggQnXmqZaMiMzrvpdJCqojIzJu6zmbr5s/elAe3bN26987oRd3PtpoJCfHf4fi47HwEBCCi4Xg0oxYg2tTb7MO3v+/vysv78t3X4uP3tbW27Y6/VkOSwVh1JhExAQAiWmsjjHYQ1ZtNpqzP91Y4TBMkb9TjstnJmKxDlEI8LDpMIpJVQggIgZkRsaqqDLz5XQ1la5gRAL0zs5d+1RjCdEoq/VD0MIIIe6med+r1EW3b/gcEwK4vOJRxMgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/83e20dbb9f79eab80ae95306927ef631/e4706/SET_CGI_Commands_01.avif 230w", "/en/static/83e20dbb9f79eab80ae95306927ef631/d1af7/SET_CGI_Commands_01.avif 460w", "/en/static/83e20dbb9f79eab80ae95306927ef631/7f308/SET_CGI_Commands_01.avif 920w", "/en/static/83e20dbb9f79eab80ae95306927ef631/7067b/SET_CGI_Commands_01.avif 1302w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/83e20dbb9f79eab80ae95306927ef631/a0b58/SET_CGI_Commands_01.webp 230w", "/en/static/83e20dbb9f79eab80ae95306927ef631/bc10c/SET_CGI_Commands_01.webp 460w", "/en/static/83e20dbb9f79eab80ae95306927ef631/966d8/SET_CGI_Commands_01.webp 920w", "/en/static/83e20dbb9f79eab80ae95306927ef631/03522/SET_CGI_Commands_01.webp 1302w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/83e20dbb9f79eab80ae95306927ef631/81c8e/SET_CGI_Commands_01.png 230w", "/en/static/83e20dbb9f79eab80ae95306927ef631/08a84/SET_CGI_Commands_01.png 460w", "/en/static/83e20dbb9f79eab80ae95306927ef631/c0255/SET_CGI_Commands_01.png 920w", "/en/static/83e20dbb9f79eab80ae95306927ef631/5f652/SET_CGI_Commands_01.png 1302w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/83e20dbb9f79eab80ae95306927ef631/c0255/SET_CGI_Commands_01.png",
              "alt": "Example GET Flow for Node-RED v3",
              "title": "Example GET Flow for Node-RED v3",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><a parentName="p" {...{
        "href": "https://wiki.instar.com/de/Node-RED_Flows/wqhd_set_cgis.json"
      }}>{`SET Commands`}</a></p>
    <h3 {...{
      "id": "livestream",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#livestream",
        "aria-label": "livestream permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Livestream`}</h3>
    <h4 {...{
      "id": "jpeg-stream-snapcgi",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#jpeg-stream-snapcgi",
        "aria-label": "jpeg stream snapcgi permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`JPEG Stream /snap.cgi`}</h4>
    <p><em parentName="p">{`Snapshots for video channels `}<code parentName="em" {...{
          "className": "language-text"
        }}>{`11`}</code>{`, `}<code parentName="em" {...{
          "className": "language-text"
        }}>{`12`}</code>{` and `}<code parentName="em" {...{
          "className": "language-text"
        }}>{`13`}</code></em></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`http://192.168.178.21/snap.cgi?chn`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`11`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`user`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pwd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar
http://192.168.178.21/snap.cgi?chn`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`12`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`user`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pwd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar
http://192.168.178.21/snap.cgi?chn`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`13`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`user`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pwd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar`}</code></pre></div>
    <p><em parentName="p">{`Example Flows for Node-RED v3:`}</em></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/cc988e3043e92876cd49d04b7e1229fc/187fa/JPEG_Stream_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.21739130434783%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACmElEQVQoz0WOW0xScQDGz3sqU66eQtQ5b2VacyvnGYyHNp+qTbYopLEWDz6hLOnNJnIXCDVRUZel5nwo060MZZQK3vBCgDJfomkhgiKZwOFy+Dd1rt9++56+b/ugYo2r7KWrQOWkyhw5cgdN4cxVuvKUrlyFk6Zw5sgdVJmdKrVfkdppCmd+29aFm3mqTSgYTkTiWDyZQpOpOAb+hGM/A3/9oXA4gkbjSQyckjrL43D0996+1+f37ge8+wH/4REUPAodHAaTWCpx1lxeXhKJmgy9vR/HP1jm5+Zmv1kt8+6trdBRsL/PUFxUeLW09EZFefn1sofsB1AgGAqdoNFEKowmkgBsfHdMTkxYLBar1bq9vb2ystLR2SkWi9UazVM+H4fDZeBwRBIpMyuLzmBAscgxloiffkudvvN4fvh8PhRFPR4PhmGxWKy5uZnD4ej1ej6fTyaTYRimUqlkMpnJZEJq886L6V+Kr3sys7fF7B+cWbPMmi1Wq9FodLvdNptNIBCwWCyJRMLlcrOzs0kkEgzDRCKRTqdDPQtepXlXO7urNu9oFw5aeseePxM2iUQCgUAulzc0NPB4PBaLVV9fX1NTA8MwHo+nUCgEAgFBEAgA7FwMSwAAZqaNQqFwaGiosbGxrq7u0Rm1tbVsNhtBEBi+TCAQKBQKHo+vRhAogsbOPYlEUwCYTKaebv2XqamR4WFJq1gqae3vM4y+G2nX6e7fu4vPysxIT09Pu5SRnsZg0KH4BSiKAgDWN+x6w8DUtGlg8G2btr29q3vs/fjkp88Dr99weU8Kikpo+QWFJdeqEDqb8/j/OIrGAMBGV/fKlcvMLke1bq1Ka7ulWUF0q3derTM7Vm8qrBWy+QrpXKVqsVK1eFu99A+Uhox2uWLgCwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cc988e3043e92876cd49d04b7e1229fc/e4706/JPEG_Stream_01.avif 230w", "/en/static/cc988e3043e92876cd49d04b7e1229fc/d1af7/JPEG_Stream_01.avif 460w", "/en/static/cc988e3043e92876cd49d04b7e1229fc/7f308/JPEG_Stream_01.avif 920w", "/en/static/cc988e3043e92876cd49d04b7e1229fc/e78a4/JPEG_Stream_01.avif 1194w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/cc988e3043e92876cd49d04b7e1229fc/a0b58/JPEG_Stream_01.webp 230w", "/en/static/cc988e3043e92876cd49d04b7e1229fc/bc10c/JPEG_Stream_01.webp 460w", "/en/static/cc988e3043e92876cd49d04b7e1229fc/966d8/JPEG_Stream_01.webp 920w", "/en/static/cc988e3043e92876cd49d04b7e1229fc/de1dc/JPEG_Stream_01.webp 1194w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cc988e3043e92876cd49d04b7e1229fc/81c8e/JPEG_Stream_01.png 230w", "/en/static/cc988e3043e92876cd49d04b7e1229fc/08a84/JPEG_Stream_01.png 460w", "/en/static/cc988e3043e92876cd49d04b7e1229fc/c0255/JPEG_Stream_01.png 920w", "/en/static/cc988e3043e92876cd49d04b7e1229fc/187fa/JPEG_Stream_01.png 1194w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/cc988e3043e92876cd49d04b7e1229fc/c0255/JPEG_Stream_01.png",
              "alt": "Example GET Flow for Node-RED v3",
              "title": "Example GET Flow for Node-RED v3",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><a parentName="p" {...{
        "href": "https://wiki.instar.com/de/Node-RED_Flows/nodered_flow_node-red_wqhd_jpg_stream.json"
      }}>{`JPEG Video Stream`}</a></p>
    <p><em parentName="p">{`* requires `}<a parentName="em" {...{
          "href": "https://flows.nodered.org/node/node-red-node-base64"
        }}>{`node-red-node-base64`}</a></em></p>
    <h4 {...{
      "id": "http-stream-livestreamactionplaymediatype",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#http-stream-livestreamactionplaymediatype",
        "aria-label": "http stream livestreamactionplaymediatype permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`HTTP Stream /livestream?action=play&media={type}`}</h4>
    <h5 {...{
      "id": "fragmented-mp4-stream-livestreamactionplaymediafmp4",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#fragmented-mp4-stream-livestreamactionplaymediafmp4",
        "aria-label": "fragmented mp4 stream livestreamactionplaymediafmp4 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Fragmented MP4 Stream /livestream?action=play&media=fmp4`}</h5>
    <p><em parentName="p">{`Videostreams for video channels `}<code parentName="em" {...{
          "className": "language-text"
        }}>{`11`}</code>{`, `}<code parentName="em" {...{
          "className": "language-text"
        }}>{`12`}</code>{` and `}<code parentName="em" {...{
          "className": "language-text"
        }}>{`13`}</code></em></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`http://192.168.178.21/livestream/11?action`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`play`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`media`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`fmp4`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`user`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pwd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar
http://192.168.178.21/livestream/12?action`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`play`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`media`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`fmp4`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`user`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pwd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar
http://192.168.178.21/livestream/13?action`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`play`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`media`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`fmp4`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`user`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pwd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar`}</code></pre></div>
    <EuiSpacer mdxType="EuiSpacer" />
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h5 {...{
      "id": "mjpeg-stream-livestreamactionplaymediamjpeg",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#mjpeg-stream-livestreamactionplaymediamjpeg",
        "aria-label": "mjpeg stream livestreamactionplaymediamjpeg permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`MJPEG Stream /livestream?action=play&media=mjpeg`}</h5>
    <p><em parentName="p">{`Videostreams for video channels `}<code parentName="em" {...{
          "className": "language-text"
        }}>{`11`}</code>{`, `}<code parentName="em" {...{
          "className": "language-text"
        }}>{`12`}</code>{` and `}<code parentName="em" {...{
          "className": "language-text"
        }}>{`13`}</code></em></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`http://192.168.178.21/livestream/11?action`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`play`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`media`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`mjpeg`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`user`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pwd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar
http://192.168.178.21/livestream/12?action`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`play`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`media`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`mjpeg`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`user`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pwd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar
http://192.168.178.21/livestream/13?action`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`play`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`media`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`mjpeg`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`user`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pwd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar`}</code></pre></div>
    <p><em parentName="p">{`Example Flows for Node-RED v3:`}</em></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0b44c74204c96d0c494b37bfb87c8026/cec12/MJPEG_Stream_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "51.30434782608696%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACbUlEQVQozwFiAp39ACmN1CyO1CGJ0xyH0h2H0h6I0yCJ1CCJ1B+I0x6H0h2H0hyF0ByF0BuF0B+J1COM1yOM1yOM1yOM1yGK1QDr9Pr1+v34+/32+/34/P7s8vfd6vPk7vXy9vj1+fz5/f/7///8///9///u8vXg5Ofh5efi5uji5ujn6u0A7e7u7+/w/v7//Pz9////wcC/f319o6Kht7a1x8bFyMbFzcvK3dva9fPyoZ+eKykoLiwrNDIxMS8uY2FgAOvu8fL3+vb6/fX5+/v//8zNzra3tsjJycvMzODh4c3OzqSkpZiYmZ+fn2dnZw0ODg0ODhUVFQ8PD0ZGRgD59fH9/fz////+/v7////a2dnq6urx8fHh4eH////y8vKEhIRoaGinp6dnZ2cXFxcbGxsfHx8WFhZKSkoAib3hh73ljcLoiL/lhcHrmK28x8PAxsfHwcHB1dXV0NDQa2trR0dHj4+PXl5eGBgYFhYWGxsbFBQUR0dHAJPF6YrA6JLE6JXE5pnN8n+Sn4J/fXl5enh4eImJiXZ2dmBgYFBQUHBwcEpKShsbGxMTExkZGRMTE0ZGRgB9uuZ3uOWBvOeDvOSAwe58lKSDf3ygoaFubm5WVlZYWFhcXFxVVVVdXV07OzscHBwUFBQZGRkSEhJEREQADHvKAXbJEX7MCnrJAHPNU3+gtKymury9qamphISEhISEo6Ojs7Ozl5eXTk5OFBQUDg4ODQ0NCwsLR0dHANbm8tXl8djn8tbm8dns+brAxauqqcLCwqenp5aWlp2dnbe3t7+/v6mpqWFhYScnJyEhISkpKTs7O2RkZGvUW2EfK+oFAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0b44c74204c96d0c494b37bfb87c8026/e4706/MJPEG_Stream_01.avif 230w", "/en/static/0b44c74204c96d0c494b37bfb87c8026/d1af7/MJPEG_Stream_01.avif 460w", "/en/static/0b44c74204c96d0c494b37bfb87c8026/7f308/MJPEG_Stream_01.avif 920w", "/en/static/0b44c74204c96d0c494b37bfb87c8026/f81f2/MJPEG_Stream_01.avif 1187w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0b44c74204c96d0c494b37bfb87c8026/a0b58/MJPEG_Stream_01.webp 230w", "/en/static/0b44c74204c96d0c494b37bfb87c8026/bc10c/MJPEG_Stream_01.webp 460w", "/en/static/0b44c74204c96d0c494b37bfb87c8026/966d8/MJPEG_Stream_01.webp 920w", "/en/static/0b44c74204c96d0c494b37bfb87c8026/ffd55/MJPEG_Stream_01.webp 1187w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0b44c74204c96d0c494b37bfb87c8026/81c8e/MJPEG_Stream_01.png 230w", "/en/static/0b44c74204c96d0c494b37bfb87c8026/08a84/MJPEG_Stream_01.png 460w", "/en/static/0b44c74204c96d0c494b37bfb87c8026/c0255/MJPEG_Stream_01.png 920w", "/en/static/0b44c74204c96d0c494b37bfb87c8026/cec12/MJPEG_Stream_01.png 1187w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0b44c74204c96d0c494b37bfb87c8026/c0255/MJPEG_Stream_01.png",
              "alt": "Example GET Flow for Node-RED v3",
              "title": "Example GET Flow for Node-RED v3",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><a parentName="p" {...{
        "href": "https://wiki.instar.com/de/Node-RED_Flows/nodered_flow_node-red_wqhd_mjpeg_stream.json"
      }}>{`MJPEG Video Stream`}</a></p>
    <p><em parentName="p">{`* requires `}<a parentName="em" {...{
          "href": "https://flows.nodered.org/node/node-red-contrib-multipart-stream-decoder"
        }}>{`node-red-contrib-multipart-stream-decoder`}</a></em></p>
    <h4 {...{
      "id": "rtsp-stream-livestream",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#rtsp-stream-livestream",
        "aria-label": "rtsp stream livestream permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`RTSP Stream /livestream`}</h4>
    <p><em parentName="p">{`Videostreams for video channels `}<code parentName="em" {...{
          "className": "language-text"
        }}>{`11`}</code>{`, `}<code parentName="em" {...{
          "className": "language-text"
        }}>{`12`}</code>{` and `}<code parentName="em" {...{
          "className": "language-text"
        }}>{`13`}</code></em></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`rtsp://user:password@192.168.178.21/livestream/11
rtsp://user:password@192.168.178.21/livestream/12
rtsp://user:password@192.168.178.21/livestream/13`}</code></pre></div>
    <p><a parentName="p" {...{
        "href": "/en/Aussenkameras/IN-9408_WQHD/Video_Streaming/"
      }}>{`Video streaming`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      